@import 'colors';

$border: 1px solid $border-color;

@mixin card-animation($x, $xOperator, $y, $yOperator, $name) {
  @keyframes #{$name} {
    0% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 0%),
          calc(#{$y}% #{$yOperator} 0%),
          0px
        )
        scale(0);
    }
    5% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 5%),
          calc(#{$y}% #{$yOperator} 5%),
          0px
        )
        scale(0.05);
    }
    10% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 10%),
          calc(#{$y}% #{$yOperator} 10%),
          0px
        )
        scale(0.1);
    }
    15% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 15%),
          calc(#{$y}% #{$yOperator} 15%),
          0px
        )
        scale(0.15);
    }
    20% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 20%),
          calc(#{$y}% #{$yOperator} 20%),
          0px
        )
        scale(0.2);
    }
    25% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 25%),
          calc(#{$y}% #{$yOperator} 25%),
          0px
        )
        scale(0.25);
    }
    30% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 30%),
          calc(#{$y}% #{$yOperator} 30%),
          0px
        )
        scale(0.3);
    }
    35% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 35%),
          calc(#{$y}% #{$yOperator} 35%),
          0px
        )
        scale(0.35);
    }
    40% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 40%),
          calc(#{$y}% #{$yOperator} 40%),
          0px
        )
        scale(0.4);
    }
    45% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 45%),
          calc(#{$y}% #{$yOperator} 45%),
          0px
        )
        scale(0.45);
    }
    50% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 50%),
          calc(#{$y}% #{$yOperator} 50%),
          0px
        )
        scale(0.5);
    }
    55% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 55%),
          calc(#{$y}% #{$yOperator} 55%),
          0px
        )
        scale(0.55);
    }
    60% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 60%),
          calc(#{$y}% #{$yOperator} 60%),
          0px
        )
        scale(0.6);
    }
    65% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 65%),
          calc(#{$y}% #{$yOperator} 65%),
          0px
        )
        scale(0.65);
    }
    70% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 70%),
          calc(#{$y}% #{$yOperator} 70%),
          0px
        )
        scale(0.7);
    }
    75% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 75%),
          calc(#{$y}% #{$yOperator} 75%),
          0px
        )
        scale(0.75);
    }
    80% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 80%),
          calc(#{$y}% #{$yOperator} 80%),
          0px
        )
        scale(0.8);
    }
    85% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 85%),
          calc(#{$y}% #{$yOperator} 85%),
          0px
        )
        scale(0.85);
    }
    90% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 90%),
          calc(#{$y}% #{$yOperator} 90%),
          0px
        )
        scale(0.9);
    }
    95% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 95%),
          calc(#{$y}% #{$yOperator} 95%),
          0px
        )
        scale(0.95);
    }
    100% {
      transform: translate3d(
          calc(#{$x}% #{$xOperator} 100%),
          calc(#{$y}% #{$yOperator} 100%),
          0px
        )
        scale(1);
    }
  }
}

@include card-animation(-100, '+', -100, '+', 'animationFromTopLeft');
@include card-animation(100, '-', -100, '+', 'animationFromTopRight');
@include card-animation(-100, '+', 100, '-', 'animationFromBottomLeft');
@include card-animation(100, '-', 100, '-', 'animationFromBottomRight');
.cardAnimation:nth-child(4n + 1) {
  animation: animationFromTopLeft 0.7s linear;
  transition: transform 0.7s;
}
.cardAnimation:nth-child(4n + 2) {
  animation: animationFromTopRight 0.7s linear;
  transition: transform 0.7s;
}
.cardAnimation:nth-child(4n + 3) {
  animation: animationFromBottomLeft 0.7s linear;
  transition: transform 0.7s;
}
.cardAnimation:nth-child(4n) {
  animation: animationFromBottomRight 0.7s linear;
  transition: transform 0.7s;
}

html {
  height: 100%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #e2e2e2;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito-Regular', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  background-color: $themeBg;
}
#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
}
.fixed-body {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.main-app {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 2;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
a,
a:hover {
  text-decoration: none;
}

.Header {
  padding: 4px 10px;
  background-color: $theme;
  display: flex;
  min-height: 64px;
  color: $white;
  position: sticky;
  z-index: 4;
  width: 100%;
  box-shadow: 0px 2px 10px grey;
  top: 0px;
  &_navbar {
    flex: 1;
  }
  &_navLink {
    font-weight: 800;
    font-size: 18px;
  }
  &_icon {
    margin: 5px 10px;
    min-width: 14px;
  }
  .dropdown-link {
    padding: 0px 10px;
  }
  .ml-auto {
    margin-left: auto
  }
}

.marginPadding {
  margin: 0;
  padding: 0;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $themeBg, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}

.Home {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 100;
  padding-top: 40px;
  // background-color: rgba(255, 255, 255, 0.4);
  &_row {
    flex-wrap: wrap-reverse;
  }
  &_banner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 10px;
    &_icon {
      margin: 4px 5px;
    }
    &_contact {
      padding: 4px 0;
    }
    &_social {
      display: flex;
    }
    .hello {
      background-color: #4682b4 !important;
      border-bottom-left-radius: 0px !important;
      padding: 6px 20px;
      margin: 10px 0px;
      h4 {
        font-weight: 800;
      }
    }
    .name {
      font-size: 3rem;
      font-weight: 800;
    }
    .designation {
      font-size: 2rem;
      font-weight: 600;
    }
  }
  .img-border {
    height: 400px;
    width: 400px;
    border: 20px solid rgba(0, 0, 0, 0.07);
    margin: 0 auto;
    border-radius: 50%;
    img {
      object-fit: cover;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      text-align: center;
      border: 20px solid rgba(0, 0, 0, 0.17);
      border-radius: 50%;
    }
  }
}
.Portfolio {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  margin-top: 20px;
  &_card {
    animation: animationFromTopLeft 0.7s linear;
    transition: transform 0.7s;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &_logo {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1fb7c7;
    }
    img {
      max-height: 160px;
    }
    .javascript {
      background-color: #fad900;
    }
    .python {
      background-color: #4a98c8;
    }
    .css {
      background-color: #066eb8;
    }
    .html {
      background-color: #f47933;
    }
    .batchfile {
      background-color: #323232;
    }
    .react {
      background-color: #25262e;
    }
    .java {
      background-color: #44c646;
    }
    &_info {
      padding: 1rem;
    }
  }
  // masonry styles started
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: 100%;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }
  // masonry styles end
}

.Skills {
  display: flex;
  flex-flow: row wrap;
  padding: 20px 5%;
  .SkillsCard {
    // background: linear-gradient(135deg, #fcdf8a 0%, #f6aa84 100%);
    background: linear-gradient(105deg, #02aab0, #00cdac);
    // background-color: $theme;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    position: relative;
    margin: 1%;
    width: 14.5%;
    padding-bottom: 14.5%;
    // max-width: 300px;
    font-weight: bold;
    &_img {
      height: 60%;
      top: 20%;
      position: absolute;
    }
    &_title {
      position: absolute;
      top: 0;
      height: 100%;
      font-size: 22px;
      padding: 5px;
      text-align: center;
    }
    &_desc {
      position: absolute;
      top: 82%;
      display: none;
      padding: 5px;
      text-align: center;
    }
  }
  .SkillsCard:hover {
    background: $white;
    .SkillsCard_desc {
      display: block;
    }
  }
}
